exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-request-js": () => import("./../../../src/pages/request.js" /* webpackChunkName: "component---src-pages-request-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/postTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-posts-archive-template-js": () => import("./../../../src/templates/postsArchiveTemplate.js" /* webpackChunkName: "component---src-templates-posts-archive-template-js" */)
}

