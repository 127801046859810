import React from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles, theme } from './src/themes/theme';

import ReactDOM from 'react-dom/client';

import './src/styles/preloader.scss';

export const wrapPageElement = ({ element }) => {
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            {element}
        </ThemeProvider>
    );
};

export const onClientEntry = async () => {};

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
    window.history.scrollRestoration = 'manual';
    const currentPosition = getSavedScrollPosition(location, location.key);
    if (!currentPosition) {
        window.scrollTo(0, 0);
    } else {
        window.setTimeout(() => {
            window.requestAnimationFrame(() => {
                window.scrollTo(...currentPosition);
            });
        }, 0);
    }

    return false;
};

export const replaceHydrateFunction = () => {
    return (element, container) => {
        const root = ReactDOM.createRoot(container);
        root.render(element);
    };
};
