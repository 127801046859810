import { createGlobalStyle } from 'styled-components';

import CoFoKakBlackWoff from '../fonts/subset-CoFoKak-Black.woff';
import CoFoKakBlackWoff2 from '../fonts/subset-CoFoKak-Black.woff2';

import InterRegularWoff from '../fonts/subset-Inter-Regular.woff';
import InterRegularWoff2 from '../fonts/subset-Inter-Regular.woff2';
import InterMediumWoff from '../fonts/subset-Inter-Medium.woff';
import InterMediumWoff2 from '../fonts/subset-Inter-Medium.woff2';
import InterSemiBoldWoff from '../fonts/subset-Inter-SemiBold.woff';
import InterSemiBoldWoff2 from '../fonts/subset-Inter-SemiBold.woff2';

export const theme = {
    fonts: {
        main: 'Inter Intl, -apple-system, sans-serif, Arial',
        headers: 'CoFo Kak, -apple-system, sans-serif, Arial',
    },
    colors: {
        dark: '#0C0829',
        darkGrey: '#2F3133',
        violet: '#A362B6',
        lightGrey: '#EDEFF2',
        lightGreyText: '#BCCCDB',
        divider: '#C0CBDC',
        blueBackground: '#F4F8FC',
        link: '#2d60f5',
        linkHover: '#D21E4E',
        white: '#EDEFF2',
        cleanWhite: '#FFFFFF',
    },
    breakpoints: {
        mobile: 'only screen and (max-width: 576px)',
        tablet: 'only screen and (max-width: 768px)',
        tabletPlus: 'only screen and (max-width: 1024px)',
        notebook: 'only screen and (max-width: 1200px)',
    },
};

export const GlobalStyles = createGlobalStyle`
    
    //-----------------
    //Fonts
    //-----------------
    @font-face {
        font-family: 'Inter';
        src: local('Inter Regular'), local('Inter-Regular'), 
            url(${InterRegularWoff2}) format('woff2'),
            url(${InterRegularWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Inter';
        src: local('Inter Medium'), local('Inter-Medium'),
            url(${InterMediumWoff2}) format('woff2'),
            url(${InterMediumWoff}) format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Inter';
        src: local('Inter Semi Bold'), local('Inter-SemiBold'),
            url(${InterSemiBoldWoff2}) format('woff2'),
            url(${InterSemiBoldWoff}) format('woff');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'CoFo Kak';
        src: url(${CoFoKakBlackWoff2}) format('woff2'),
            url(${CoFoKakBlackWoff}) format('woff');
        font-weight: 900;
        font-style: normal;
        font-display: swap;
    }
    
    //-----------------
    //Common
    //-----------------
    * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }
    body,html {
        background-color: #ffffff;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -ms-scroll-chaining: none;
        overscroll-behavior: none;
        color: ${({ theme }) => theme.colors.darkGrey};
        font-family: ${({ theme }) => theme.fonts.main};
        font-size: 16px;
        line-height: 16px;
        @media ${({ theme }) => theme.breakpoints.mobile} {
            font-size: 12px;
            line-height: 12px;    
        }
    }
    html[data-direction="down"] .header-animation {
      transform: translate3d(0,-100%,0);
    }
    
    body {
        pointer-events: inherit!important;
    }
    .contentWrapper {
        padding: 0 3rem;
        position: relative;
        @media ${({ theme }) => theme.breakpoints.tablet} {
            padding: 0 1.5rem;
        }
        @media ${({ theme }) => theme.breakpoints.mobile} {
            padding: 0 1rem;
        }
        
    }
    .border-radius-24 {
        border-radius: 1.5rem;
    }
    
    //-----------------
    //Headers
    //-----------------
    h1, h2, h3, h4{
        font-family: ${({ theme }) => theme.fonts.headers};
        margin:0;
        line-height: 100%;
        font-weight: 900;
        text-transform: uppercase;
    }
    h1 {
        font-size: 3.25rem;
        @media ${({ theme }) => theme.breakpoints.mobile} {
            font-size: 2.25rem;    
        }
        &.small {
            font-size: 2rem;     
            @media ${({ theme }) => theme.breakpoints.mobile} {
                font-size: 2.25rem;    
            }
        }
        &.line-40 {
            line-height: 2.5rem;    
        }
    }
    h2 {
        font-size: 3.25rem; 
        @media ${({ theme }) => theme.breakpoints.mobile} {
            font-size: 2rem;    
        }
        &.small {
        font-size: 2rem;     
        @media ${({ theme }) => theme.breakpoints.mobile} {
            font-size: 1.25rem;    
        }
        }
    }
    h3 {
        font-size: 1.25rem;
        @media ${({ theme }) => theme.breakpoints.mobile} {
            font-size: 1rem;    
        }
    }
    
    //-----------------
    //Text
    //-----------------
    .subheading {
        font-family: ${({ theme }) => theme.fonts.headers};
    }
    p {
      line-height: 1.5rem;
      margin: 0;
    }
    p.lead {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
    .medium {
       font-weight: 500;
    }
    .bold {
       font-weight: 700;
    }
    .text-12 {
        font-size: 0.75rem;
        line-height: 1rem;
    }
    .text-20 {
        font-size: 1.25rem;
        line-height: 1.5rem;
        &.line-32 {
            line-height: 2rem;    
        }
    }
    .text-24 {
        font-size: 1.5rem;
        line-height: 1.75rem;
    }
    .text-32 {
        font-size: 2rem;
        line-height: 2.25rem;
        &.line-32 {
            line-height: 2rem;    
        }
    }
    .text-40 {
        font-size: 2.5rem;
        font-family: ${({ theme }) => theme.fonts.headers};
        line-height: 100%;
        font-weight: 900;
        text-transform: uppercase;
        span{
            display: block;
            font-size: 2rem;
        }
        span.plus{
            display: inline;
            font-size: 2.5rem;
            color: ${({ theme }) => theme.colors.violet}
        }
        @media ${({ theme }) => theme.breakpoints.mobile} {
            font-size: 2rem;
        }
    }
    .text-52 {
        font-size: 3.25rem;
        font-family: ${({ theme }) => theme.fonts.headers};
        line-height: 100%;
        font-weight: 900;
        text-transform: uppercase;
        span{
            display: block;
            font-size: 2rem;
            line-height: 2rem;
        }
        @media ${({ theme }) => theme.breakpoints.mobile} {
            font-size: 2.75rem;
        }
        
    }
    .text-72 {
        font-size: 4.5rem;
        font-family: ${({ theme }) => theme.fonts.headers};
        line-height: 100%;
        font-weight: 900;
        text-transform: uppercase;
        @media ${({ theme }) => theme.breakpoints.mobile} {
            font-size: 4rem;
        }
    }
    
    //-----------------
    //Links
    //-----------------
    a {
        color: ${({ theme }) => theme.colors.link};
        text-decoration: none;
        transition: color 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        outline: none;
    }
    a:hover {
        color: ${({ theme }) => theme.colors.linkHover};
        text-decoration: underline;
    }
    
    //-----------------
    //Spacing
    //-----------------
    .space-top-12 {
        margin-top: 0.75rem;
    }
    .space-top-16 {
        margin-top: 1rem;
    }
    .space-top-20 {
        margin-top: 1.25rem;
    }
    .space-top-24 {
        margin-top: 1.5rem;
    }
    .space-top-28 {
        margin-top: 1.75rem;
    }
    .space-top-32 {
        margin-top: 2rem;
    }
    .space-top-40 {
        margin-top: 2.5rem;
    }
    .space-top-48 {
        margin-top: 3rem;
    }
    .space-top-52 {
        margin-top: 3.25rem;
    }
    .space-top-60 {
        margin-top: 3.75rem;
    }
    .space-top-72 {
        margin-top: 4.5rem;
    }
    .space-top-80 {
        margin-top: 5rem;
    }
    .space-top-100 {
        margin-top: 6.25rem;
    }
    .space-top-120 {
        margin-top: 7.5rem;
    }
    .space-top-160 {
        margin-top: 10rem;
    }
    .space-top-240 {
        margin-top: 15rem;
    }
    //-----------------
    .space-bottom-8 {
        margin-bottom: 0.5rem;
    }
    .space-bottom-12 {
        margin-bottom: 0.75rem;
    }
    .space-bottom-16 {
        margin-bottom: 1rem;
    }
    .space-bottom-20 {
        margin-bottom: 1.25rem;
    }
    .space-bottom-28 {
        margin-bottom: 1.75rem;
    }
    .space-bottom-24 {
        margin-bottom: 1.5rem;
    }
    .space-bottom-32 {
        margin-bottom: 2rem;
    }
    .space-bottom-40 {
        margin-bottom: 2.5rem;
    }
    .space-bottom-48 {
        margin-bottom: 3rem;
    }
    .space-bottom-52 {
        margin-bottom: 3.25rem;
    }
    .space-bottom-60 {
        margin-bottom: 3.75rem;
    }
    .space-bottom-72 {
        margin-bottom: 4.5rem;
    }
    .space-bottom-80 {
        margin-bottom: 5rem;
    }
    .space-bottom-100 {
        margin-bottom: 6.25rem;
    }
    .space-bottom-120 {
        margin-bottom: 7.5rem;
    }
    .space-bottom-160 {
        margin-bottom: 10rem;
    }
    .space-sides-24 {
        margin-left: 1.75rem;
        margin-right: 1.75rem;
        @media ${({ theme }) => theme.breakpoints.mobile} {
            margin-left: 1.5rem;
            margin-right: 1.5rem;
        }
        
    }
    
    .padding-top-60 {
        padding-top: 3.75rem;
    }
    
    .padding-bottom-60 {
        padding-bottom: 3.75rem;
    }
    .padding-bottom-100 {
        padding-bottom: 6.25rem;
    }
    
    //-----------------
    //Colors
    //-----------------
    .dark-bg {
        background-color:${({ theme }) => theme.colors.dark}
    }
    .blue-bg {
        background-color:${({ theme }) => theme.colors.blueBackground}
    }
    .violet{
        color: ${({ theme }) => theme.colors.violet}
    }
    .lightGrey {
        color: ${({ theme }) => theme.colors.lightGreyText}
    }
    .white {
        color: ${({ theme }) => theme.colors.white}
    }
    .lightGreyText {
        color: ${({ theme }) => theme.colors.lightGreyText}
    }
    
    //-----------------
    //Opacity
    //-----------------
    .opacity-50 {
        opacity: 0.5;
    }
    .opacity-25 {
        opacity: 0.25;
    }
    
    //-----------------
    //Columns
    //-----------------
    .columns .wrap {
        border-right: 1px solid ${({ theme }) => theme.colors.divider};
        height: 100%;
        padding-top: 1.5rem;
        padding-right: 2rem;
        padding-bottom: 1.5rem;
        @media ${({ theme }) => theme.breakpoints.tablet} {
            border-right:none;
            padding-right: 0;
        }
        @media ${({ theme }) => theme.breakpoints.mobile} {
            border-right:none;
            padding-right: 0;
        }
    }
    .columns .col-12.line {
        border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
        padding-bottom: 1.5rem;
        padding-top: 1.5rem;
    }
    .columns .col-12.line {
        border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
        padding-bottom: 1.5rem;
        padding-top: 1.5rem;
    }
    .columns .col-12.line:nth-child(3n + 3) .wrap {
        border-right: none;
    }
    .columns .col-12.line:nth-last-child(-n + 3) {
        border-bottom: none;
    }
    @media ${({ theme }) => theme.breakpoints.tablet} {
        .columns .col-12.line:nth-last-child(-n + 3) {
            border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
        }
        .columns .col-12.line:last-child {
            border-bottom: none;
        }
    }
    @media ${({ theme }) => theme.breakpoints.mobile} {
        .columns .col-12.line:nth-last-child(-n + 3) {
            border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
        }
        .columns .col-12.line:last-child {
            border-bottom: none;
        }
    }
    
    
    
    //-----------------
    //Locomotive
    //-----------------
    html.has-scroll-smooth {
        overflow: hidden;
    }
    
    html.has-scroll-dragging {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    
    .has-scroll-smooth body {
        overflow: hidden;
    }
    
    .has-scroll-smooth [data-scroll-container] {
        min-height: 100vh;
    }
    
    [data-scroll-direction='horizontal'] [data-scroll-container] {
        height: 100vh;
        display: inline-block;
        white-space: nowrap;
    }
    
    [data-scroll-direction='horizontal'] [data-scroll-section] {
        display: inline-block;
        vertical-align: top;
        white-space: nowrap;
        height: 100%;
    }
    
    .c-scrollbar {
        position: absolute;
        right: 0;
        top: 0;
        width: 11px;
        height: 100%;
        z-index: 9999;
        background: #fff;
        transform-origin: center right;
        transition: transform 0.3s, opacity 0.3s;
        opacity: 0;
    }
    .c-scrollbar:hover {
        transform: scaleX(1.45);
    }
    .c-scrollbar:hover,
    .has-scroll-scrolling .c-scrollbar,
    .has-scroll-dragging .c-scrollbar {
        opacity: 1;
    }
    [data-scroll-direction='horizontal'] .c-scrollbar {
        width: 100%;
        height: 10px;
        top: auto;
        bottom: 0;
        transform: scaleY(1);
    }
    [data-scroll-direction='horizontal'] .c-scrollbar:hover {
        transform: scaleY(1.3);
    }
    
    .c-scrollbar_thumb {
        position: absolute;
        top: 0;
        right: 0;
        background-color: black;
        opacity: 0.5;
        width: 7px;
        border-radius: 10px;
        margin: 2px;
        cursor: -webkit-grab;
        cursor: grab;
    }
    .has-scroll-dragging .c-scrollbar_thumb {
        cursor: -webkit-grabbing;
        cursor: grabbing;
    }
    [data-scroll-direction='horizontal'] .c-scrollbar_thumb {
        right: auto;
        bottom: 0;
    }


    //-----------------
    //Swiper
    //-----------------
    .swiper-button-prev,
    .swiper-button-next {
        margin-top: 1.25rem;
        position: absolute;
        cursor: pointer;
        top: unset;
        color: ${({ theme }) => theme.colors.darkGrey};
        z-index: 2;
        @media ${({ theme }) => theme.breakpoints.mobile} {
            margin-top: 0.75rem;
        }
    }
    .swiper-button-prev:hover,
    .swiper-button-next:hover {
        color: ${({ theme }) => theme.colors.linkHover};
    }
    .swiper-button-prev:after,
    .swiper-button-next:after {
        font-size: 1.5rem;
    }
    .swiper-pagination-bullet {
        background: ${({ theme }) => theme.colors.darkGrey};
    }
    .swiper {
        padding-bottom: 4rem;
    }
    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 1rem;
        z-index: 1;
    }
    .white {
        .swiper-button-prev,
        .swiper-button-next {
            color: ${({ theme }) => theme.colors.white};
        }
        .swiper-pagination-bullet {
            background: ${({ theme }) => theme.colors.white};
        }
    }
    
    
    
    //Open Modal
    body.openModal {
        overflow: hidden;
    }
    
    
    
    //Swal
    .swal2-popup {
        border-radius: 1rem!important;
    }
    .swal2-title {
        font-size: 1.5rem!important;
        font-family: ${({ theme }) => theme.fonts.headers}!important;
        color: ${({ theme }) => theme.colors.caption}!important;
        font-weight: normal!important;
    }
    .swal2-html-container {
        font-size: 1rem!important;
        color: ${({ theme }) => theme.colors.caption}!important;
    }
    .swal2-styled.swal2-confirm {
        border-radius: 100px!important;
        padding: 1.15rem 1.5rem!important;
        font-size: 1.15rem!important;
        line-height: 1.5rem!important;
    }
    .swal2-no-war {
        display: none!important;
    }
    

    //-----------------
    //Forms
    //-----------------
    .form-block, .form-block > div {
        position:relative
    }
    .form-block .error {
        position: absolute;
        bottom: 0.75rem;
        color: ${({ theme }) => theme.colors.linkHover};
    }
    .input {
        line-height: 1.5rem;
        color: ${({ theme }) => theme.colors.dark};
        border: none;
        border-bottom: 1px solid ${({ theme }) => theme.colors.dark};
        border-radius:0;
        padding: 1rem 0;
        display: block;
        width: 100%;
        margin-bottom: 2.5rem;
        outline: 0;
        transition: border-color 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    }
    .input:focus {
        border-bottom:2px solid ${({ theme }) => theme.colors.violet};
    }
    .input.isInvalid {
        border-bottom:2px solid ${({ theme }) => theme.colors.linkHover};
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 18.5882L5.41174 4L3.99998 5.41176L18.5882 20L20 18.5882Z' fill='%23D21E4F'/%3E%3Cpath d='M5.41178 20L20 5.41174L18.5882 3.99998L4.00002 18.5882L5.41178 20Z' fill='%23D21E4F'/%3E%3C/svg%3E%0A");
        background-position: right 0 center;
        background-repeat: no-repeat;
    }
    .input.isInvalid:focus {
        border-bottom: 1px solid ${({ theme }) => theme.colors.violet};
    }
    .input.select {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 1.25rem center;
        background-size: 16px 16px;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    .checkbox {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
    .checkbox + label {
        display: inline-flex;
        align-items: center;
        user-select: none;
        cursor: pointer;
    }
    .checkbox + label::before {
        content: '';
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid ${({ theme }) => theme.colors.violet};
        margin-right: 0.75rem;
        border-radius: 10rem;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
    }
    .checkbox.isInvalid + label::before {
        border: 2px solid ${({ theme }) => theme.colors.linkHover};
        
    }
    .checkbox:checked + label::before {
        border-color: ${({ theme }) => theme.colors.violet};
        background-color: ${({ theme }) => theme.colors.violet};
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }
    .checkbox:not(:disabled):not(:checked) + label:hover::before {
        border-color: ${({ theme }) => theme.colors.violet};
    }
    
    .privacyAgreement {
        padding-bottom: 1.75rem;
        margin-bottom: 1.5rem;
    }
    .form-block .loading{
        position: absolute !important;
        z-index: 999;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.75);
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .form-block .loading img{
        width: 4rem;
        height: 4rem;
    }
    
    
    .blockModalForm .hideForm {
        display: none
    }

    //-----------------
    //Modal
    //-----------------
    .blockModalForm {
        top: 0;
        left: 0;
        background: rgba(12, 8, 41, 0.65);
        position: fixed;
        width: 100%;
        height: 100vh;
        z-index: 99999;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow-y: scroll;
        padding: 5rem 0;
    }
    .blockModalForm .wrapper {
        max-width: 1200px;
        margin: auto;
        position: relative;
        background: #fff;
        border-radius: 1.5rem;
        width: 90%;
        padding: 6rem 0;
    }
    
    
    //Cookie Consent
    .CookieConsent {
        a {
            color: ${({ theme }) => theme.colors.warmRed}
        }
    }
    .cookie-accept-button {
        transition: background 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), color 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        background: transparent!important;
        font-size: 1rem!important;
        line-height: 1rem!important;
        color: ${({ theme }) => theme.colors.dark}!important;
        border: 1px solid ${({ theme }) => theme.colors.dark}!important;
        border-radius: 2rem!important;
        padding: 0.75rem 1.5rem!important;
        margin: 1rem!important;
    }
    .cookie-accept-button:hover {
        border: 1px solid ${({ theme }) => theme.colors.linkHover}!important;
        background: ${({ theme }) => theme.colors.linkHover}!important;
        color: ${({ theme }) => theme.colors.white}!important;
    }
   
    
      
`;
